import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import axios from "axios";
import routes from "../../../packs/constants/routes";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import _ from 'lodash'

export default function ModalLinks({policyCompanyId, policyTitle, policyVersion, policyArchived, nationStateId}) {
  const [showModal, setShowModal] = useState(false)
  const [policyVersions, setPolicyVersions] = useState([])
  const [comparisonList, setComparisonList] = useState([])
  const archiveLabel = (policyArchived) => {
    if(policyArchived) {
      return (
        <OverlayTrigger
          key='top'
          placement='top'
          overlay={
            <Tooltip id={`tooltip-top`}>
              Archived policy: this policy is not available on the payer resources anymore.
            </Tooltip>
          }
        >
          <div className="policy_icon policy_icon_arch"></div>
        </OverlayTrigger>
      )
    }
  }

  const handleChange = ({target: {value, checked} }) => {
    checked ? setComparisonList([...comparisonList, value] ) : setComparisonList(comparisonList.filter((e) => e !== value))
  }

  const handleCompareClick = () => {
    const sortedIds =  comparisonList.sort((a,b) => a - b)
    const href = `/compare_policies?ids[]=${sortedIds[0]}&ids[]=${sortedIds[1]}`

    window.open(href, '_blank');
  }

  const handleCancelClick = () => {
    setShowModal(false)
    setComparisonList([])
  }

  useEffect( () => {
    if (showModal) {
      axios
        .get(
          routes.api.policyVersion(),
          {
            params: { title: policyTitle, company_id: policyCompanyId, nation_state_id: nationStateId },
            headers: {
              accept: 'application/json'
            }
          }
        )
        .then( success => {
          setPolicyVersions(success.data)
        })
        .catch(error => {
          console.log('ERROR', error, error.response)
        })
    }
    }, [showModal]
  )
  return (
    <div>
      <span onClick={() => {setShowModal(true)}} className="policy_icon">
        {policyVersion}
      </span>
      {archiveLabel(policyArchived)}

      {showModal && <ReactModal
        ariaHideApp={false}
        isOpen={showModal}
        className={'modal-dialog'}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className="modal-title">Policy History</h3>
          </div>
          <div className='modal-body'>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Link Title</th>
                  <th scope="col">Version</th>
                  <th scope="col">Created</th>
                  <th scope="col">Updated</th>
                </tr>
              </thead>
              <tbody>
                {policyVersions.map((row, index) => {
                  const link = (row.active && !row.archived) ? row.link : row.path_file
                  return (
                    <tr key={index}>
                      <th scope="row">
                        <input
                          disabled={comparisonList.length == 2 && !_.includes(comparisonList, _.toString(row.id)) }
                          type="checkbox"
                          onChange={handleChange}
                          value={row.id}
                        />
                      </th>
                      <td><a href={link} target="_blank">{row.title}</a></td>
                      <td>{row.version}</td>
                      <td>{new Date(row.created_at).toLocaleDateString()}</td>
                      <td>{new Date(row.updated_at).toLocaleDateString()}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='modal-footer'>
            {comparisonList.length == 2 &&
              <button
                target="_blank"
                className="btn btn-success"
                onClick={ () => handleCompareClick()}
              >
                Compare
              </button>
            }

            <button onClick={() => handleCancelClick()}>Close</button>
          </div>
        </div>
      </ReactModal> }
    </div>
  );
}
